import { createApp } from 'vue';

import SignupForm from './components/SignupForm.vue';
import Hero from './components/Hero.vue';

import '@ui/app/css/vendor/barebones.css';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import '@ui/app/css/vendor/aura_custom.scss';

import '@ui/app/css/base.css';


if (document.getElementById('signup-form-component')) {
  console.log('mounting signup')
  var signupForm = createApp(SignupForm).mount('#signup-form-component');
}

if (document.getElementById('hero-vue')) {
  UserEvents.sendEvent('pageview.homepage');
  var hero = createApp(Hero).mount('#hero-vue');
}
