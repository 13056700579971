<template>
<div v-if="social" class="flex flex-column text-center align-items-center">
<!--   <a href="/connect/login/facebook/" class="mb-2 button flex align-items-center justify-content-center">
    <img src="/static/img/Facebook_Logo_Primary.png" class="mt-1" style="position: relative; top: -2px; left: -10px">
    <div>Continue with Facebook</div>
  </a>
 -->  <a href="/connect/login/google-oauth2/" class="mb-2 button flex align-items-center justify-content-center">
    <img src="/static/img/Google__G__logo.svg" style="position: relative; top: 0px; left: -10px;">
    <div>Continue with Google</div>
  </a>
  <strong class="mb-2">- or - </strong>
</div>
<div class="field">
  <InputText size="large" :pt="{root:{name: 'email',}}" required placeholder="ferris@bueller.limo"/>
  <input v-model="tz" type="hidden" name="tz">
</div>
</template>

<script>
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';

  export default {
    components: {
      InputText,
      Button,
    },
    props: {
      social: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        tz: String
      }
    },
    created: function() {
      this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
</script>

<style>
  a.button {
    width: 19rem;
  }
  a img {
    text-transform: none;
    font-size: 0.9rem;
    font-weight: bold;
    width: 24px;
  }
</style>