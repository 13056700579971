<template>
<section class="surface-section px-4 md:px-6 lg:px-8 overflow-hidden">
  <div class="flex flex-wrap">
    <article class="relative w-full mt-8 xl:mt-0 xl:mb-0 xl:w-8 flex flex-column flex-order-1 xl:flex-order-0 justify-content-center align-items-center xl:align-items-start">
      <h4 class="text-green-500 font-semibold mb-2">When's the last time you...</h4>
      <div class="tagline-box relative w-full" ref="taglineBox">
        <h5
          v-for="(tagline, k) in taglines"
          :class="[k === selectedTagline ? 'visible': '', 'text-left', 'tagline', 'font-bold', 'text-4xl', 'sm:text-7xl', 'mt-0', 'mb-4', 'absolute']"
          :ref="setTaglineRef"
        >
          {{ tagline }}?
        </h5>
      </div>
      <div class="flex gap-3">
        <section class="signup mt-5">
          <h5 class="mb-2">Schedule repeating reminders for all of the things you shouldn't forget.</h5>
          <h5 class="">Share your list with everyone in your household. Get notified when tasks are checked off.</h5>

          <h5 class="section-header">Sign up for free</h5>
          <form method="POST" action="/signup/">
            <SignupForm social/>
            <input type="hidden" name="csrfmiddlewaretoken" :value="csrf_token">
            <div>
              <button type="Submit" class="button mb-2 md:w-19rem sm:w-full">Continue with Email</button>
            </div>
            <p class="fine-print">
              We take privacy seriously and we'll <strong>never</strong> share your email address.
              See our <a href="/about/privacy-policy/">Privacy Policy</a> for more details.
            </p>
            <p class="fine-print">
              By submitting the form above, you're agreeing to our <a href="/about/terms-of-service/">Terms</a>.
            </p>
          </form>
          <p class="mt-3">Already on it? <a href="/accounts/login/"><strong>Sign in</strong></a></p>
        </section>
      </div>
    </article>
    <div class="w-full xl:w-4 relative flex justify-content-center align-items-center">
      <img src="/static/img/ss_iphone_double.png" class="main-image" alt="Image" />
    </div>
  </div>
  <div class="w-full flex flex-row flex-wrap gap-4 mt-4 justify-content-center">
    <article class="flex align-items-center w-23rem border-round-xl z-2 right-0 md:right-50 gap-3 px-4 py-3" style="backdrop-filter: blur(40px); background-color: rgb(234 234 230 / 50%)">
      <img src="/static/img/icons/apple-touch-icon.png" class="notification border-round-md" alt="Image" />
      <div class="flex flex-column justify-content-center w-full text-left">
        <p class="m-0 font-semibold text-sm line-height-1.5 text-black-alpha-60">onit.today</p>
        <p class="m-0 font-medium text-sm line-height-1.5 text-black-alpha-80">
          Sarah just completed "Get the chimney inspected"
        </p>
      </div>
    </article>

    <article class="flex align-items-center w-23rem p-1 border-round-xl z-2 right-0 md:right-50 gap-3 px-4 py-3" style="backdrop-filter: blur(40px); background-color: rgb(234 234 230 / 50%)">
      <img src="/static/img/icons/apple-touch-icon.png" alt="Image" class="notification border-round-md" />
      <div class="flex flex-column justify-content-center w-full text-left">
        <p class="m-0 font-semibold text-sm line-height-1.5 text-black-alpha-60">onit.today</p>
        <p class="m-0 font-medium text-sm line-height-1.5 text-black-alpha-80">Nick just completed "Replace the furnace filter"</p>
      </div>
    </article>

    <article class="flex align-items-center w-23rem border-round-xl z-2 right-0 md:right-50 gap-3 px-4 py-3" style="backdrop-filter: blur(40px); background-color: rgb(234 234 230 / 50%)">
      <img src="/static/img/icons/apple-touch-icon.png" class="notification border-round-md" alt="Image" />
      <div class="flex flex-column justify-content-center w-full text-left">
        <p class="m-0 font-semibold text-sm line-height-1.5 text-black-alpha-60">onit.today</p>
        <p class="m-0 font-medium text-sm line-height-1.5 text-black-alpha-80">
          Sarah just completed "Make Noah a pediatrician appt"
        </p>
      </div>
    </article>
  </div>
</section>
</template>

<script>
  import SignupForm from './SignupForm.vue';

  export default {
    components: {
      SignupForm,
    },
    data () {
      return {
        selectedTagline: 0,
        taglineRefs: [],
        taglines: [
          'flipped your mattress',
          'cleaned out the dryer vent',
          'sharpened your kitchen knives',
          'replaced your toothbrush',
          'checked your car\'s tire pressure',
          'decluttered your garage or attic',
          'organized your digital files',
          'updated or practiced your family\'s fire escape plan',
          'scheduled a health check-up',
          'had a family game night',
          'polished your leather shoes or furniture',
          'cleaned your keyboard and mouse',
          'reviewed your annual credit report',
          'changed your car\'s air filter',
          'cleaned your pet\'s favorite spots and toys',
          'changed the passwords for your online accounts',
          'resealed your grout',
          'scheduled a date night',
          'tested your home\'s water quality',
          'flushed your water heater',
          'lubricated door hinges and locks',
          'ran a cleaning cycle on your washing machine',
          'checked the batteries in your smoke detectors',
          'checked your home for water leaks',
          'called your aunt',
          'set goals for the next year',
          'created a home inventory list',
          'updated your emergency kit',
          'cleaned your makeup brushes',
          'cleaned or replaced your home air filters',
          'backed up your digital photos',
          'watered that plant your cousin gave you',
          'donated clothes you no longer wear',
          'descaled your coffee maker',
          'deep-cleaned your refrigerator coils',
          'checked the expiry dates on pantry items',
          'cleared the gutters',
          'serviced your HVAC system',
          'reviewed or updated your will',
          'audited your subscription services',
          'pruned your garden plants',
          'inspected your roof for damage',
        ],
        csrf_token: __APP_STATE.csrf_token,
      }
    },
    methods: {
      setTaglineRef(el) {
        this.taglineRefs.push(el);
      },
      cycleTagline() {
        this.selectedTagline = this.selectedTagline < this.taglines.length - 1 ? this.selectedTagline + 1 : 0;
      },
      getRandomTagline() {
        return this.taglines[Math.floor(this.taglines.length * Math.random())];
      }
    },
    created: function() {
      this.cycleTagline();
      window.setInterval(this.cycleTagline, 2500);
    },
    mounted: function () {
      console.log(this.$refs.taglineBox);
      const height = Math.max(...this.taglineRefs.map((i) => {return i.scrollHeight}));
      this.$refs.taglineBox.style.height = `${height + 30}px`;
    }
  }
</script>

<style scoped lang="scss">
  .main-image {
    width: 512px;
    height: 564px;
    object-fit: cover;
    object-position: 0 0;
  }
  @media screen and (max-width: 576px) {
    .main-image {
      height: 400px;
    }
  }

  .notification {
    width: 42px;
  }
  .tagline {
    opacity: 0;
    transition: opacity 1s;
  }
  .tagline.visible {
    opacity: 1;
  }
</style>